<template>
  <div class="container" id="welcome">
    <div class="header flex">
      <img src="../assets/joyliveLogo.png" />
      <span>巨立电梯物联网系统</span>
    </div>
    <div class="banner">
      <p>尊敬的用户,欢迎使用我们的产品</p>
      <div class="flex">
        <a
          href="http://iot.joylive.com:50021/JuLiRes/jl/Elevator_maintenance.apk"
        >
          android版app
        </a>
        <a href="https://apps.apple.com/us/app/id1054236783?ls=1">iOS版app</a>
      </div>
    </div>
    <p>电梯信息</p>
    <el-form :model="form" label-position="left" label-width="140px">
      <el-form-item label="电梯工号：">
        <span>{{ form.number }}</span>
      </el-form-item>
      <el-form-item label="平台档案号：">
        <span>{{ form.equipmentNumber }}</span>
      </el-form-item>
      <el-form-item label="项目名称：">
        <span>{{ form.projectName }}</span>
      </el-form-item>
      <el-form-item label="详细地址：">
        <span>{{ form.alias }}</span>
      </el-form-item>
      <el-form-item label="年检时间：">
        <span>{{ form.lastCheckDate }}</span>
      </el-form-item>
      <el-form-item label="下次年检日期：">
        <span>{{ form.nextCheckDate }}</span>
      </el-form-item>
      <el-form-item label="上次维保时间：">
        <span>{{ form.lastUpkeepTime }}</span>
      </el-form-item>
      <el-form-item label="上次保养员：">
        <span>{{ form.maintenancePersonnel }}</span>
      </el-form-item>
      <el-form-item label="维保公司：">
        <span>{{ form.maintainer }}</span>
      </el-form-item>
      <el-form-item label="制造公司：">
        <span>{{ form.manufacturer }}</span>
      </el-form-item>
      <el-form-item label="投用时间：">
        <span>{{ form.useTime }}</span>
      </el-form-item>
      <el-form-item label="投诉电话：">
        <span>{{ form.phone }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "Welcome",
  setup(props, { emit }) {
    emit("public_header", false);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      form: {},
    });

    const init = async () => {
      let obj = { number: route.query.number };
      let { data } = await proxy.$api.common.defaultMsg(obj);
      for (let key in data) {
        data[key] = data[key] || "无";
      }
      state.form = data;
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#welcome {
  margin: 0 !important;
  min-height: 100%;
  padding: 8px;
  .flex {
    justify-content: space-between;
  }
  .header {
    span {
      color: #dc172b;
      font-size: 16px;
      font-weight: bold;
      line-height: 39px;
    }
  }
  .banner {
    width: 100%;
    height: 144px;
    padding: 30px 20px;
    margin-top: 15px;
    box-sizing: border-box;
    background: url(../assets/welcome/backgroundImg.png);
    p {
      color: #fff;
      font-size: 20px;
      text-align: center;
    }
    .flex {
      margin-top: 25px;
      a {
        background: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 16px;
        color: #dc172b;
      }
    }
  }
  > p {
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    background-color: #e0e0e2;
    text-align: center;
    color: #9c9c9d;
    margin: 12px 0 0;
  }
  .el-form {
    .el-form-item {
      margin: 0;
    }
    .el-form-item__label,
    .el-form-item__content {
      color: #4a4a4a;
      font-size: 18px;
    }
  }
}
</style>
